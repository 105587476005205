<template>
  <v-footer elevation='0' absolute color='transparent' class='d-flex justify-center my-0 py-0 pb-12'>
    <v-fade-transition mode="in-out">
      <v-alert
          dense
          text
          type="info"
          :value="syncInfo"
          dismissible
          border="left"
          :color="color"
          class='mb-12'
      >
        <template v-slot:close>
          <v-btn class='ml-4' :color='color' depressed small icon @click='syncInfo = !syncInfo'>
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
        <span v-html="$t('tokens.notification.snackInfo')"></span>
      </v-alert>
    </v-fade-transition>
  </v-footer>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'TimeSyncedComponent',

    props: {
      token: {
        type: Object,
        required: false
      },
      section: {
        type: String,
        required: true
      },
      boxStyles: {
        type: String,
        required: false
      },
      textStyles: {
        type: String,
        required: false
      },
      color: {
        type: String,
        required: false,
        default: 'secondary'
      }
    },

    data: () => ({
      timerCount: 15,
    }),

    computed: {
      ...mapGetters({
        wallet: 'web3/GET_WALLET'
      }),
      isSync: {
        get() {
          return this.$store.getters['web3/GET_IS_SYNC']
        },
        set(val) {
          this.$store.commit('web3/IS_SYNC', val)
        }
      },
      syncInfo: {
        get() {
          return this.$store.getters['web3/GET_SYNC_INFO']
        },
        set(val) {
          this.$store.commit('web3/SYNC_INFO', val)
        }
      }
    },

    methods: {
      ...mapActions({
        loadWalletData: 'web3/LOAD_WALLET_DATA'
      })
    },

    watch: {
      timerCount: {
        async handler(value) {
          if (value > -1) {
            setTimeout(() => {
              this.timerCount--;
            }, 1000);
          }

          if (value === -1) {
            this.timerCount = 15

            switch (this.section) {
              case 'WALLET':
                if(this.token)
                  await this.loadWalletData(this.token)
                break
              case 'STOCK':
              case 'CONTRACTS':
              default:
                break
            }
          }
        },
        immediate: true
      }
    }
  }
</script>

<style scoped>
  .timeleft__container {
    background: white;
    padding: 5px 20px;
    border-radius: 7px 7px 0 0;
  }

  .timeleft__text {
    color: #005b4a;
    font-size: 12px;
  }
</style>