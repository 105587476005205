<template>
  <v-row :class='{ "mx-0" : view !== "WALLET" }'>
    <v-col cols="12" class="ma-y py-0 d-flex justify-end align-center align-content-center">
      <div v-if="web3.account" class="d-flex flex-row justify-end align-end align-content-end">
        <code
               :class='view === "WALLET" ? "login__wallet" : "login__others"'
               :elevation="0"
               class="mr-1"
               style="width: 200px; border-radius: 0.25rem"
        >
          <v-icon small left :color="view === 'WALLET' ? 'white': 'black'">
            mdi-key-outline
          </v-icon>
          <p class="ma-0 pa-0 text--white">
            {{ truncateAddress(web3.account) }}
          </p>
        </code>
        <v-btn class="btn-color"
               tile
               :elevation="0"
               color="#f6851b"
               style="width: 120px; border-radius:0.25rem"
               @click="clearSession"
        >
          {{ $t('tokens.buttons.logout') }}
        </v-btn>
      </div>
      <v-btn v-else
             color='#f6851b'
             dark
             id='login'
             @click="startSession"
      >
        {{ $t('tokens.buttons.login') }} Metamask
        <v-icon id='arrow' small>mdi-arrow-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import Web3 from "web3";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LoginMetamask",

  props: {
    view: {
      type: String,
      required: true
    }
  },

  data:() => ({
    web3js: new Web3(Web3.givenProvider || "ws://localhost:8546")
  }),

  computed: {
    ...mapGetters({
      web3: 'web3/GET_WEB3'
    })
  },

  methods: {
    ...mapActions({
      login: 'web3/LOGIN',
      logout: 'web3/LOGOUT'
    }),
    async startSession() {
      await this.login().finally(async () => {
        switch (this.view) {
          case 'WALLET':
            await this.$root.$emit('initializeWalletData')
            break
          case 'TOKEN':
            await this.$root.$emit('initializeTokenData')
            break
          case 'INVENTORY':
            await this.$root.$emit('initializeInventoryData')
            break
        }
      })
    },
    clearSession() {
      this.logout()
      switch (this.view) {
        case 'WALLET':
          this.$emit('onLogoutMetamask')
          break
        case 'TOKEN':
          this.$root.$emit('closeTokenData')
          break
        case 'INVENTORY':
          this.$root.$emit('closeInventoryData')
          break
      }
    },
    truncateAddress(address) {
      if (!address) {
        return "";
      }
      return `${address.substr(0, 7)}...${address.substr(
          address.length - 7,
          address.length
      )}`;
    }
  }
}
</script>

<style scoped>
code {
  height: 3.8vh !important;
  border: none;
  box-shadow: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 1.5em 0 0 1.5em
}

code.login__wallet {
  background-color: #005b4a;
  color: white;
}

code.login__others {
  background-color: rgba(216, 216, 216, 0.59);
  color: rgba(0, 0, 0, 0.87);
}

#login {
  padding-left:30px;
}

#login i {
  opacity: 0;
  margin-left: 0 !important;
  transition: all .3s ;
}

#login:hover i {
  opacity: 100%;
  margin-left: 10px !important;
}

.btn-color{
  color: white;
}

</style>
