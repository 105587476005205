import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFooter,{staticClass:"d-flex justify-center my-0 py-0 pb-12",attrs:{"elevation":"0","absolute":"","color":"transparent"}},[_c(VFadeTransition,{attrs:{"mode":"in-out"}},[_c(VAlert,{staticClass:"mb-12",attrs:{"dense":"","text":"","type":"info","value":_vm.syncInfo,"dismissible":"","border":"left","color":_vm.color},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c(VBtn,{staticClass:"ml-4",attrs:{"color":_vm.color,"depressed":"","small":"","icon":""},on:{"click":function($event){_vm.syncInfo = !_vm.syncInfo}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]},proxy:true}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('tokens.notification.snackInfo'))}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }